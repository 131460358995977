import React, { useState } from "react";
import { CircularProgress, styled } from "@mui/material";
import { useSelector } from "react-redux";
import ApartmentDetails from "./components/ApartmentDetails/ApartmentDetails";
import MonthlyRentBlock from "../components/monthlyrent/MonthlyRentBlock";
import OtherPaymentsSection from "./components/Payments/OtherPaymentsSection";
import useFetch from "#/hooks/util-hooks/useFetch";
import MonthlyRentStatusActive from "../components/monthlyrent/MonthlyRentStatusActive";
import ShowCalculator from "#/pages/Dashboard/components/dashboard-showcase/components/monthlyRateModal/ShowCalculator";

const TenantDashboard = ({ renew, setRenew, view, apartmentID }) => {
  const [statusAction, setStatusAction] = useState(false);
  const { tenantRecord } = useSelector((store) => store.tenant);
  const monthlyRentDetail = useSelector((store) => store.tenant.monthlyRent);

  const { data, loading, error } = useFetch(
    apartmentID ? `/invoice/get_apartment_invoice/${apartmentID}` : null
  );

  const lastInvoice = data?.data?.[data?.data?.length - 1];

  const monthlyRentStatus = () => {
    if (statusAction) {
      return <MonthlyRentStatusActive data={data} statusAction={true} />;
    }
    return null;
  };

  if (!tenantRecord || !monthlyRentDetail) {
    return <div>Loading tenant data...</div>;
  }

  if (loading) {
    return (
      <div className="tw-flex tw-justify-center tw-mt-20">
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return <div>Error loading invoices: {error}</div>;
  }

  return (
    <Container>
      <ShowCalculator isTenant={true} />
      <div
        className="tw-bg-white xs:tw-px-10 tw-py-12 tw-px-5 tw-rounded-2xl"
        style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>
        <ApartmentDetails
          view={view}
          record={tenantRecord[0]}
          details={monthlyRentDetail[0]}
          setStatusAction={setStatusAction}
          monthlyRentDetails={monthlyRentDetail}
          renew={renew}
          setRenew={setRenew}
        />
        <MonthlyRentBlock
          view={view}
          statusAction={statusAction}
          details={monthlyRentDetail[0]}
          monthlyRentDetails={monthlyRentDetail}
          data={data}
          apartmentID={apartmentID}
        />
      </div>
      {data?.data?.length > 0 &&
        lastInvoice?.payment_status === "processing" &&
        monthlyRentStatus()}
      <OtherPaymentsSection apartmentId={apartmentID} />
    </Container>
  );
};

export default TenantDashboard;

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 1.35rem;
`;
