import { Button, CircularProgress, Dialog, styled } from "@mui/material";
import React, { useState, useEffect } from "react";
import ContributionView from "./ContributionView";
import LoanBillView from "./LoanBillView";
import CustomBtn from "#/components/buttons/CustomBtn";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { APP_ROUTES } from "#/constants/routes";
import { propertyServiceApi } from "#/apis/propertyServiceApi";
import { removeNonNumeric } from "#/shared/utils/charformat/digitFormart";
import { useDispatch } from "react-redux";
import { alertError, alertSuccess } from "#/store/actions/notification.actions";

function renderView(view, contribution, setContribution, loanList) {
  switch (view) {
    case 0:
      return (
        <ContributionView contribution={contribution} setContribution={setContribution} />
      );
    case 1:
      return <LoanBillView contribution={contribution} loanValues={loanList} />;

    default:
      break;
  }
}

const RenewMonthlyRent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(0);
  const [contribution, setContribution] = useState(0);

  // Peter Ihimire Additions
  const [loanValues, setLoanValues] = useState({
    values: false,
    monthly_payment: 0,
    subscription_fee: 0,
    security_deposit: 0,
    total_first_payment: 0
  });

  const { tenantRecord } = useSelector((store) => store.tenant);
  const apartmentId = tenantRecord[0]?.apartment_id.apartment_id;

  //Call the rent record to get contribution set be admin or not
  const getContribution = async () => {
    try {
      const rentRecord = await propertyServiceApi().get(
        `application/${tenantRecord[0]?.user_id}`
      );
      const adminFee = Number(rentRecord.data.payload[0]?.contribution_fee);
      setContribution(adminFee);
    } catch (error) {
      console.error("Error fetching contribution:", error);
    }
  };

  useEffect(() => {
    if (tenantRecord && tenantRecord.length > 0) {
      getContribution();
    }
  }, [tenantRecord]);

  useEffect(() => {
    if (!apartmentId) return;
    getLoanBill();
  }, [contribution, apartmentId]);

  function getLoanBill() {
    // setLoading(true);
    propertyServiceApi()
      .post("/renew/loan-calculate", {
        price: tenantRecord[0].apartment_id?.price,
        tenant_contribution: removeNonNumeric(contribution) * 1
      })
      .then((res) => {
        const {
          monthly_payment,
          subscription_fee,
          security_deposit,
          total_first_payment
        } = res.data.payload;
        setLoanValues((prev) => ({
          ...prev,
          values: true,
          monthly_payment,
          subscription_fee,
          security_deposit,
          total_first_payment
        }));
        setLoading(false);
      })
      .catch(function (error) {
        setLoanValues((prev) => ({
          ...prev,
          values: false
        }));
        setLoading(false);
        return Promise.reject(error);
      });
  }

  const subscription_amt = loanValues.subscription_fee;
  const security_amt = loanValues.security_deposit;
  const monthly_amt = loanValues.monthly_payment;
  const contribution_amt = contribution;

  const total_rent_amount =
    subscription_amt + security_amt + monthly_amt + contribution_amt;

  console.log(
    "Tenant record individual",
    tenantRecord[0]?.property,
    tenantRecord[0]?.apartment_id.apartment_id,
    tenantRecord[0]?.user_id,
    contribution_amt
  );

  // THIS FUNCTION GENERATES THE LOAN RENT BILL
  const renewLoanRentBills = () => {
    setLoading(true);
    propertyServiceApi()
      .post("/bill/loan_rent/renew", {
        property: tenantRecord[0]?.property,
        apartment_id: tenantRecord[0]?.apartment_id.apartment_id,
        contribution: Number(removeNonNumeric(contribution_amt)),
        lid: tenantRecord[0]?.user_id
        // tenant_contribution: removeNonNumeric(contribution)UTQh1702,
        //662955-7257-CP
      })
      .then((data) => {
        // dispatch(alertSuccess("Invoice Generated Successfully"));
        dispatch(alertSuccess("Loan bills generated successfully"));
        history.push(APP_ROUTES.dashboard.main);
      })
      .catch((error) => {
        setLoading(false);
        dispatch(alertError(error.response.data?.error || "An Error occured"));
        return Promise.reject(error);
      });
  };

  const handleNext = () => {
    if (view === 0) {
      setView((view) => view + 1);
    } else if (view === 1) {
      renewLoanRentBills();
    }
  };

  const handleBack = () => {
    if (view === 0) {
      history.push(APP_ROUTES.dashboard.main);
      setView((view) => view + 1);
    } else if (view === 1) {
      setView((view) => view - 1);
    }
  };
  return (
    <>
      {loading && <GenInvoiceModal />}
      <Wrapper>
        {renderView(view, contribution, setContribution)}
        <ActionBtns>
          <Button sx={btnStyle} variant="outlined" color="primary" onClick={handleBack}>
            Back
          </Button>
          <CustomBtn sx={btnStyle} id="rent-savings-btn2" onClick={handleNext}>
            {view === 0 ? "Next" : "Confirm"}
          </CustomBtn>
        </ActionBtns>

        <VfdLogo>
          <img src="/assets/dashboard/vfd.svg" alt="Vfd Logo" />
        </VfdLogo>
      </Wrapper>
    </>
  );
};

export default RenewMonthlyRent;

const btnStyle = {
  fontSize: "0.75rem",
  padding: "0.4rem 3.4rem",
  border: "2px solid"
};

const Wrapper = styled("div")`
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
`;

const VfdLogo = styled("div")`
  margin: 0 auto;
  max-width: 200px;

  img {
    width: 100%;
  }
`;

const ActionBtns = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
`;

const GenInvoiceModal = () => {
  return (
    <Dialog open={true} fullWidth>
      <ModalWrapper>
        <CircularProgress size="6rem" />
        <h4>Generating Invoice...</h4>
      </ModalWrapper>
    </Dialog>
  );
};

const ModalWrapper = styled("div")`
  width: 100%;
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 3rem;

  h4 {
    color: #052137;
    font-size: 1.7rem;
    font-weight: 600;
    text-align: center;

    @media (max-width: 600px) {
      font-size: 1.4rem;
    }
  }
`;

// work begins here Peter Ihimire, where the loan bills should be generated, need to pick up those information and use them to generate bills("/bill/loan_rent/initial")
// const generateInvoice = () => {
//   setLoading(true);
//   propertyServiceApi()
//     .post("/application/invoice/new", {
//       tenant_contribution: removeNonNumeric(contribution)
//     })
//     .then((data) => {
//       dispatch(alertSuccess("Invoice Generated Successfully"));
//       history.push(APP_ROUTES.dashboard.main);
//     })
//     .catch((error) => {
//       setLoading(false);
//       dispatch(alertError(error.response.data?.error || "An Error occured"));
//       return Promise.reject(error);
//     });
// };
