import React, { Fragment } from "react";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button } from "@material-ui/core";

const RenewalAlert = ({ onClose, setRenew }) => {
  const Styles = makeStyles((theme) => ({
    emailVerify: {
      display: "flex",
      justifyContent: "space-between",
      border: "1px solid #45B29D",
      borderRadius: "3px",
      backgroundColor: "#74D5C226",
      padding: "10px 50px 10px 50px",
      marginBottom: "30px",
      fontSize: "17px",
      fontWeight: "bold",

      [theme.breakpoints.down("sm")]: {
        padding: "10px 10px 10px 10px"
      },

      "& .text-one": {
        color: "#043B5C",

        [theme.breakpoints.down("sm")]: {
          fontSize: "13px"
        },

        "& .sub-text": {
          color: "#E41A1A",
          textDecoration: "underline",
          cursor: "pointer"
        },
        "& .sub-text2": {
          color: "green",
          textDecoration: "underline",
          cursor: "pointer"
        }
      },

      "& .text-two": {
        color: "#043B5C",
        fontSize: "15px",
        [theme.breakpoints.down("sm")]: {
          fontSize: "13px"
        }
      },

      "& .spinner": {
        margin: "0 auto"
      }
    }
  }));

  const classes = Styles();
  return (
    <Fragment>
      <Slide direction="down" in={true} timeout={1500} mountOnEnter unmountOnExit>
        <span className={classes.emailVerify}>
          <Fragment>
            <Box>
              <span className="text-one">
                You are due for a Loan rent renewal. Do you want to renew?... &nbsp;
                &nbsp; &nbsp;
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setRenew(true)}>
                  Yes
                </Button>
                &nbsp; &nbsp;
                <Button variant="contained" color="primary" onClick={onClose}>
                  No
                </Button>
              </span>
            </Box>
          </Fragment>
        </span>
      </Slide>
    </Fragment>
  );
};

export default RenewalAlert;
